var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v(_vm._s(_vm.$t("default.FOOTER_PRIVACY_AND_COOKIES")) + " ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid px-xl-5 px-1 cokkies-main" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-xl-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-md-5" }, [
            _c("h5", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoHeader) + " " + _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c("p", { staticClass: "mb-0" }, [
              _c("strong", [
                _c("small", [
                  _vm._v(
                    _vm._s(_vm.tipoSubheader) +
                      " " +
                      _vm._s(_vm.monumentoNombre)
                  ),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "h-100 pt-5 ps-sm-3 pe-0 spainhn-text d-flex flex-column",
                staticStyle: { "border-radius": "10px" },
              },
              [
                _c("h1", [_vm._v("Política de Privacidad")]),
                _c("p", [
                  _vm._v(
                    "La presente política de privacidad y de protección de datos está orientada a facilitarle cuánta información necesita para comprender el alcance y el objeto del tratamiento de datos para que la aceptación, absolutamente necesaria para poder acceder y utilizar la web, se asiente sobre un consentimiento perfectamente informado recogido en la normativa en materia de protección de datos que resulta de aplicación en cada momento, normativa constituida por el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (“RGPD”), así como la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (“LOPDGDD”)."
                  ),
                ]),
                _c("h3", [
                  _vm._v(
                    "¿Quién es el responsable del tratamiento de sus datos?"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v("SPAIN HERITAGE TOURS S.L. (en adelante, "),
                    _c("strong", [_vm._v("“SPAIN HERITAGE ”")]),
                    _vm._v(")"),
                  ]),
                  _c("li", [_vm._v("CIF: B-88196423.")]),
                  _c("li", [
                    _vm._v("Datos de contacto: "),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Domicilio social: Rodriguez Marín 61 – Bajo Izquierda, 28016 - Madrid."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("E-mail: "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:protecciondedatos@spainheritagenetwork.com",
                            },
                          },
                          [
                            _vm._v(
                              "protecciondedatos@spainheritagenetwork.com."
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("h3", [
                  _vm._v("¿Con qué finalidad tratamos sus datos personales?"),
                ]),
                _c("p", [
                  _vm._v("En "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " tratamos la información que nos facilitan las personas interesadas para gestionar la solicitud de información y, en su caso, contratar nuestros servicios. El uso de los datos es el siguiente:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Gestión y control de la relación contractual o, en su caso precontractual, adquirida con el usuario mediante su registro y/o contratación de servicios. "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Gestión del servicio contratado. Compra de Entradas y Eventos: Gestionar la contratación de los productos y servicios ofertados a través del Sitio Web o por vía telefónica. Durante el proceso de contratación se podrán recabar datos financieros (tarjetas bancarias), pero no se lo solicitaremos nosotros, sino la pasarela de pago con la finalidad de comunicarlos a las entidades que gestionan el cobro."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Acceso al área privada del Usuario. Gestionar el registro y los datos posteriores que los Usuarios pudieran generar a través de dicha área con la finalidad de llevar a cabo la administración general de su cuenta, mantenimiento y control de la misma. "
                        ),
                        _c("ul", [
                          _c("li", [
                            _vm._v(
                              "Realizar una correcta gestión y control de la relación contractual adquirida con el usuario mediante su registro y/o adquisición de productos o servicios."
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Mejorar los servicios a los que se suscribas mediante el estudio de su comportamiento como usuario para adaptarlo a sus necesidades y gustos."
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Aquellos otros fines necesarios para el desarrollo, cumplimiento y control de la relación contractual que el Usuario mantenga con "
                            ),
                            _c("strong", [_vm._v("SPAIN HERITAGE.")]),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Gestionar y tratar las posibles incidencias y el mantenimiento de los servicios que "
                            ),
                            _c("strong", [_vm._v("SPAIN HERITAGE")]),
                            _vm._v(" ofrece o intermedia."),
                          ]),
                          _c("li", [
                            _vm._v(
                              "Proporcionar servicios de ubicación si elige compartir su ubicación geográfica"
                            ),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("strong", [_vm._v("SPAIN HERITAGE")]),
                        _vm._v(
                          " podrá remitir por cualquier medio, comunicaciones comerciales referentes a productos o servicios de su propia empresa que sean similares a los que inicialmente fueron objeto de contratación, sin perjuicio de que el usuario pueda oponerse en cualquier momento."
                        ),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("SPAIN HERITAGE")]),
                    _vm._v(
                      " te recuerda que no almacena datos de tu tarjeta de crédito sino simplemente aquella información necesaria para la correcta gestión de los servicios contratados por el cliente."
                    ),
                  ]),
                ]),
                _c("h3", [
                  _vm._v("¿Qué uso le damos a la información que recopilamos?"),
                ]),
                _c("p", [
                  _vm._v(
                    "A continuación informamos de las finalidades de tratamiento de sus datos personales:"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Cuando accede a nuestros Servicios, podemos recopilar cierta información automáticamente desde su dispositivo, como su dirección IP, su dirección MAC o el identificador del dispositivo."
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Información que usted proporciona de forma voluntaria "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "información de registro: Los datos personales de registro que proporciona cuando crea una cuenta en nuestro sistema de "
                        ),
                        _c("strong", [_vm._v("SPAIN HERITAGE")]),
                        _vm._v(
                          " , nos permite darle acceso a los Servicios y proporcionárselos. También usamos esta información para autenticar su acceso a los Servicios, contactar con usted para conocer su opinión y notificarle sobre cambios o actualizaciones importantes en nuestros Servicios."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Información de transacción: Nos permite procesar la compra de la entrada al espectáculo elegido y enviarle una factura precisa. Asimismo, nos ayuda a nosotros a ponernos en contacto con usted cuando sea necesario. "
                        ),
                        _c("p", [
                          _vm._v(
                            "Podrán ser solicitados datos adicionales de tipo económico o financiero (como por el ejemplo el número de su tarjeta de crédito o débito) al objeto de llevar a cabo la ejecución de los servicios, pero dichos datos no serán almacenados en nuestro sistema de pago, sino que se recopilarán a través de la pasarela de pago."
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _vm._v("Información que recopilamos automáticamente "),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Información de actividad. Si elige compartir su ubicación geográfica con nosotros, podemos utilizar esta información para mostrarle contenido que sea local para usted. También podemos proporcionarle contenido y anuncios personalizados en función de sus interacciones previas con nuestros Servicios y nuestro conocimiento de sus preferencias que deducimos a partir de su historial de uso y compra en relación con los Servicios."
                        ),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Al igual que muchos sitios web y servicios en línea, utilizamos cookies y otras tecnologías para mantener un registro de su interacción con nuestros Servicios. Las cookies también nos ayudan a administrar una gama de funciones y contenido, así como a almacenar búsquedas y volver a presentar su información en el momento de realizar el Pedido. Para obtener más información sobre los tipos de cookies y tecnologías similares que utilizamos y por qué y cómo puede controlar dichas tecnologías, consulte nuestra Política de Cookies."
                    ),
                  ]),
                ]),
                _c("h3", [
                  _vm._v("¿Por cuánto tiempo conservaremos sus datos?"),
                ]),
                _c("p", [
                  _vm._v(
                    "Los datos proporcionados serán conservados mientras tenga una cuenta con nosotros o para que podamos cumplir con nuestras obligaciones legales, fiscales o contables, siendo cancelados al vencimiento del contrato que usted haya suscrito con SPAIN HERITAGE. Es decir, los datos serán bloqueados y conservados durante el plazo de prescripción de las acciones que pudieran derivarse de la relación contractual por usted suscrita, y posteriormente serán suprimimos."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En caso de que se oponga a que tratemos ciertos datos personales (también en relación con la recepción de nuestras comunicaciones de marketing), conservaremos un registro de su oposición al tratamiento de sus datos personales para que podamos seguir respetando sus deseos."
                  ),
                ]),
                _c("h3", [
                  _vm._v(
                    "¿Cuál es la legitimación para el tratamiento de sus datos?"
                  ),
                ]),
                _c("p", [
                  _vm._v("La base legal para el tratamiento de sus datos es:"),
                ]),
                _c("p", [
                  _vm._v(
                    "Te informamos asimismo de que la falta de aportación de la información requerida supone la imposibilidad de suscripción y cumplimiento del contrato."
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Gestión de medidas precontractuales y, en su caso, contractuales basado en el artículo 6.1.b) del RGPD, pues recabamos y tratamos sus datos personales principalmente para la ejecución de las obligaciones contractuales señaladas anteriormente así como en nuestras "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.spainheritagenetwork.com/condiciones-generales",
                        },
                      },
                      [_vm._v("Condiciones Generales de Venta.")]
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "En los supuestos en los que el usuario se haya puesto en contacto con nosotros solicitando determinada información, podamos contactar nuevamente para confirmar si, respecto de esa solicitud, necesita algo más."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "La posible ejecución de los servicios que haya suscrito con "
                        ),
                        _c("strong", [_vm._v("SPAIN HERITAGE")]),
                        _vm._v(
                          " según los términos y condiciones que figuran en cada servicio contratado."
                        ),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _vm._v(
                      "El consentimiento inequívoco basado en el artículo 6.1.a) del RGPD, en caso que no seas cliente, para el envío de comunicaciones comerciales, novedades y newsletter."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Podremos obtener y tratar sus datos personales para nuestros intereses legítimos basado en el artículo 6.1.f) del RGPD (o los de cualquier tercero). Tales intereses legítimos incluyen: "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "Establecer relaciones comerciales con usted o hacerle ofertas que creemos que serán de su interés;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Analizar y comprender cómo se utilizan nuestros servicios y así mejorarlos;"
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Para la prevención del fraude o de otros actos delictivos; o"
                        ),
                      ]),
                      _c("li", [
                        _vm._v("Para mantener seguros nuestros sistema"),
                      ]),
                      _c("li", [
                        _vm._v(
                          "La oferta de productos y servicios si ya eres cliente, se encuentra su legitimación en el interés legítimo del responsable del tratamiento, pudiendo manifestar el Cliente en cualquier momento su oposición a este tipo de tratamiento sin que en ningún caso el ejercicio de este derecho condicione la ejecución del contrato."
                        ),
                      ]),
                      _c("li", [
                        _vm._v("Para el cumplimiento de obligaciones legales."),
                      ]),
                    ]),
                  ]),
                ]),
                _c("h3", [
                  _vm._v("¿A qué destinatarios se comunicarán sus datos?"),
                ]),
                _c("p", [
                  _vm._v(
                    "Los datos también podrán ser comunicados a terceros en los siguientes supuestos:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Únicamente en el supuesto que finalmente contrates con nosotros, tus datos se comunicarán a los proveedores que sean necesarios para la tramitación de del contrato que hayas suscrito con "
                    ),
                    _c("strong", [_vm._v("SPAIN HERITAGE")]),
                    _vm._v(". De este modo: "),
                    _c("ul", [
                      _c("li", [
                        _vm._v("Encargados de tratamiento de "),
                        _c("strong", [_vm._v("SPAIN HERITAGE")]),
                        _vm._v(
                          ". Podrán acceder a los datos de carácter personal de los Usuarios aquellos terceros o colaboradores que presten servicios a "
                        ),
                        _c("strong", [_vm._v("SPAIN HERITAGE")]),
                        _vm._v(
                          " (empresas de servicios informáticos o de IT, empresas de envío de comunicaciones y agencias de marketing)."
                        ),
                      ]),
                      _c("li", [
                        _vm._v(
                          "Proveedores, intermediarios, Organismos e Instituciones Públicas y propietarios privados gestores de los Castillos y Palacios dados de alta en nuestro sistema, a quienes les cedemos, necesariamente, los datos personales estrictamente necesarios para la ejecución del servicio que hayas contratado. De esta manera, dicho Gestor o Propietario tratará la información para la gestión del servicio contratado a través de nuestra web."
                        ),
                      ]),
                    ]),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Una vez que hayas dado tu consentimiento para la distribución y / o de la divulgación de sus datos."
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Si estamos en la obligación de revelar o compartir sus datos personales con el fin de cumplir con cualquier obligación legal, o para proteger los derechos, propiedad o seguridad de "
                    ),
                    _c("strong", [_vm._v("SPAIN HERITAGE")]),
                    _vm._v(", nuestros clientes u otros."),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Por disposición de Ley, Reglamento o en virtud de una decisión de una autoridad competente, judicial o reguladora o, si es necesario, a fin de preservar sus derechos e intereses."
                    ),
                  ]),
                ]),
                _c("h3", [
                  _vm._v(
                    "¿Cuáles son sus derechos cuando nos facilita sus datos?"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Cualquier persona tiene derecho a obtener confirmación sobre si en "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " estamos tratando datos personales que les conciernen."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Cualquier interesado podrá ejercitar los siguientes derechos mediante correo postal a la dirección Rodríguez Marín 61 – Bajo Izquierda, 28016 - Madrid, o mediante correo electrónico a la dirección "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "mailto:protecciondedatos@spainheritagenetwork.com",
                      },
                    },
                    [_vm._v("protecciondedatos@spainheritagenetwork.com.")]
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Cualquier persona tiene derecho a obtener información sobre si en "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " estamos tratando datos personales que les conciernan, o no."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Las personas interesadas tienen derecho a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya nos sean necesarios para los que fines para los que fueron recogidos."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En cuanto a los tratamientos basados en la obtención de su consentimiento, usted podrá revocarlo en cualquier momento, siempre y cuando el tratamiento no sea necesario para el cumplimiento del contrato, dirigiéndose a la siguiente dirección de correo electrónico: "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "mailto:protecciondedatos@spainheritagenetwork.com",
                      },
                    },
                    [_vm._v("protecciondedatos@spainheritagenetwork.com.")]
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "La retirada del consentimiento no afectará a la licitud del tratamiento basada en el consentimiento previo a su retirada. "
                  ),
                  _c("strong", [_vm._v("“SPAIN HERITAGE ”")]),
                  _vm._v(
                    ", dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En el caso que resulte de aplicación, usted podrá solicitad la portabilidad de sus datos."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Es el supuesto en el que los datos personales son utilizados para elaborar perfiles relacionados con las preferencias, fiabilidad, comportamiento, etc."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En todo caso, usted podrá presentar una reclamación ante la Agencia Española de Protección de Datos especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos. La dirección de la Agencia es C/ Jorge Juan 6, 28001 y su página web "
                  ),
                  _c("a", { attrs: { href: "https://www.aepd.es/" } }, [
                    _vm._v("www.agpd.es."),
                  ]),
                ]),
                _c("ol", [
                  _c("li", [_c("strong", [_vm._v("Derecho de acceso:")])]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("Derecho de rectificación y supresión:"),
                    ]),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("Derecho a la limitación del tratamiento:"),
                    ]),
                  ]),
                  _c("li", [_c("strong", [_vm._v("Derecho de oposición:")])]),
                  _c("li", [
                    _c("strong", [_vm._v("Derecho de portabilidad:")]),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v(
                        "Derecho a no ser objeto de decisiones automatizadas:"
                      ),
                    ]),
                  ]),
                ]),
                _c("h1", [_vm._v("Política de Cookies")]),
                _c("p", [
                  _vm._v(
                    "En cumplimiento con lo establecido en el artículo 22.2 de la Ley de Servicios de la Sociedad de la Información y del Comercio Electrónico (LSSI) y en adecuación con la Directiva Europea 2009/136/CE, SPAIN HERITAGE TOURS S.L. (en adelante, "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(") le informa que este sitio Web utiliza cookies."),
                ]),
                _c("h3", [_vm._v("¿Qué son las cookies?")]),
                _c("p", [
                  _vm._v(
                    "Una cookie es un pequeño fichero de texto que almacena información sobre su experiencia de usuario en su propio terminal (ordenador, Tablet, Smartphone, etc.). Cada vez que visite este sitio Web, nuestro servidor reconocerá la cookie utilizándola para mejorar su experiencia de navegación, de modo que pueda obtener el mejor resultado de su visita a nuestra Web."
                  ),
                ]),
                _c("h3", [
                  _vm._v(
                    "¿Qué tipo de cookies existen y cuáles son las utilizadas por "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                ]),
                _c("p", [
                  _vm._v("En concreto, "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(" utiliza las siguientes cookies:"),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("Cookies técnicas o funcionales.")]),
                    _vm._v(
                      " Las cookies funcionales son las estrictamente necesarias e indispensables para proporcionar los servicios solicitados por los usuarios en este sitio Web de manera óptima. Su función es recoger datos puramente técnicos (sistema operativo, control del tráfico, recordar pedidos, seguridad, etc.), sin recabar datos identificativos de su persona."
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Cookies de personalización.")]),
                    _vm._v(
                      " Estas cookies están destinadas a fijar los criterios básicos y concretos del terminal del usuario (idioma, tipo de navegador, configuración regional de acceso al servicio, etc.), sin que sirvan para configurar un perfil de usuario."
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [_vm._v("Cookies analíticas.")]),
                    _vm._v(
                      " Las cookies analíticas recogen información para evaluar el uso que se hace de la Web y la actividad general de la misma. La analítica de la Web es la medida, recolección, análisis y creación de informes de los datos de Internet con el propósito de entender y optimizar el uso del sitio Web."
                    ),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "Únicamente para su información, le señalamos qué otros tipos de cookies existen. No obstante, le indicamos que SPAIN HERITAGE no las utiliza:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("strong", [_vm._v("Cookies sociales.")]),
                    _vm._v(
                      " Las cookies sociales son las utilizadas por las redes sociales externas (Facebook, Google+ y Twitter). Su función es controlar la interacción con los widgets sociales dentro de la página."
                    ),
                  ]),
                  _c("li", [
                    _c("strong", [
                      _vm._v("Cookies de publicidad comportamental."),
                    ]),
                    _vm._v(
                      " Son aquellas con la capacidad de almacenar datos referentes a su comportamiento y hábitos de navegación, como, por ejemplo, sus destinos y actividades preferidos. Fruto de este almacenamiento y procesamiento, es posible obtener un perfil específico del usuario que mejore y dé mayor eficacia a las ofertas publicitarias que se muestran durante la navegación."
                    ),
                  ]),
                ]),
                _c("p", [
                  _vm._v("En concreto, las cookies que utiliza "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(" son:"),
                ]),
                _c("p", [
                  _c("strong", [_vm._v("COOKIES PROPIAS DE SPAIN HERITAGE")]),
                ]),
                _c("table", [
                  _c("tbody", [
                    _c("tr", [
                      _c("th", [_c("strong", [_vm._v("Nombre")])]),
                      _c("th", [_c("strong", [_vm._v("Tiempo de caducidad")])]),
                      _c("th", [_c("strong", [_vm._v("Descripción")])]),
                    ]),
                    _c("tr", [
                      _c("td", [_c("strong", [_vm._v("__utma")])]),
                      _c("td", [
                        _vm._v("2 años desde la configuración/actualización"),
                      ]),
                      _c("td", [
                        _vm._v(
                          "Se utiliza para distinguir usuarios y sesiones. Se crea cuando la biblioteca javascript se ejecuta y no existen cookies _utma previas. Se actualiza cada vez que los datos se envían a Google Analytics."
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_c("strong", [_vm._v("__utmb")])]),
                      _c("td", [
                        _vm._v("30 min. desde la configuración/actualización"),
                      ]),
                      _c("td", [
                        _vm._v(
                          "Usada para determinar nuevas sesiones/visitas. Se crea cuando la biblioteca javascript se ejecuta y no existen cookies __utbm previas. Se actualiza cada vez que los datos se envían a Google Analytics."
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_c("strong", [_vm._v("__utmc")])]),
                      _c("td", [_vm._v("Final de la sesión de navegador")]),
                      _c("td", [
                        _vm._v(
                          "No se utiliza en ga.js. Utilizado para la interoperabilidad con urchin.js. Históricamente, esta cookie operaba en conjunto con la cookie __utmb para determinar si el usuario estaba en una nueva sesión/visita."
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", [_c("strong", [_vm._v("__utmz")])]),
                      _c("td", [
                        _vm._v("6 meses desde la configuración/actualización"),
                      ]),
                      _c("td", [
                        _vm._v(
                          "Almacena la fuente de tráfico o campaña que explica cómo llegó el usuario a la web. Se crea cuando la biblioteca javascript se ejecuta y se actualiza cada vez que los datos se envían a Google Analytics."
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("h3", [_vm._v("¿Por qué utilizamos Google Analytics?")]),
                _c("p", [
                  _vm._v(
                    "Google Analytics es la herramienta de análisis web gratuita de Google que ayuda a los propietarios de los sitios web a comprender, mediante datos agregados, cómo interactúan los visitantes –sin identificarles- con su sitio web, para así comprender tendencias y modos de uso de la página, obteniendo informes que ayudan a mejorar continuamente las páginas."
                  ),
                ]),
                _c("p", [
                  _vm._v("A través de Google Analytics, "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " no recibe información sobre la navegación del usuario ajena a la realizada en esta Web."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Google, Inc. es una compañía de Delaware, cuya oficina principal está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”). La información que genera la cookie acerca de su uso del website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Este aviso viene a cumplir con el requerimiento de la política de privacidad de Google Analytics que exige a todos los propietarios de webs que actualicen su política de privacidad e informen a los usuarios según las exigencias de calidad de Google Analytics. Para más información sobre la política de privacidad de Google puede visitar la siguiente dirección: "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.google.com/policies/technologies/ads/",
                      },
                    },
                    [_vm._v("www.google.com/policies/technologies/ads/")]
                  ),
                ]),
                _c("p", [
                  _vm._v("Por todo lo anterior, "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " informa a sus usuarios de que (i) es Google, a través de su herramienta Google Analytics, quien recaba la información sobre el tráfico en esta Web en su propio nombre (en ningún caso en nombre de "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    "), teniendo únicamente SPAIN HERITAGE acceso a los datos estadísticos agregados de dicho tráfico ajenos a cualquier identificación personal; (ii) Google (y no "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    ") es quien decide la finalidad del tratamiento y uso de los datos obtenidos mediante Google Analytics."
                  ),
                ]),
                _c("h3", [
                  _vm._v(
                    "¿Cómo puedo evitar el almacenamiento de cookies en mi ordenador?"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "La mayoría de los navegadores aceptan la utilización de cookies de forma automática, pero le informamos de que Usted puede modificar la configuración de su navegador para evitar su utilización e, incluso, eliminar las cookies que pudieran estar instaladas previamente en su terminal."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "A continuación le indicamos, para los navegadores más importantes, dónde puede obtener la información necesaria para tal fin:"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _vm._v("Internet Explorer®: "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://windows.microsoft.com/es-eS/windows7/Block-enable-or-allow-cookies/",
                        },
                      },
                      [
                        _vm._v(
                          "http://windows.microsoft.com/es-eS/windows7/Block-enable-or-allow-cookies."
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _vm._v("Safari®: "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://support.apple.com/kb/HT1677?viewlocale=es_ES",
                        },
                      },
                      [
                        _vm._v(
                          "http://support.apple.com/kb/HT1677?viewlocale=es_ES"
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://support.apple.com/kb/HT1677?viewlocale=es_ES",
                        },
                      },
                      [_vm._v("Chrome®: ")]
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.google.es/intl/es/policies/technologies/cookies/",
                        },
                      },
                      [
                        _vm._v(
                          "https://www.google.es/intl/es/policies/technologies/cookies/"
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _vm._v("Firefox® : "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we",
                        },
                      },
                      [
                        _vm._v(
                          'href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we'
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _vm._v("Opera®: "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "http://help.opera.com/Linux/10.60/es-ES/cookies.html",
                        },
                      },
                      [
                        _vm._v(
                          "http://help.opera.com/Linux/10.60/es-ES/cookies.html"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("p", [
                  _vm._v(
                    "Asimismo, dada la extensión de su uso, le recomendamos que consulte la política de cookies ofrece Adobe®: "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "http://www.adobe.com/es/privacy/cookies.html",
                      },
                    },
                    [_vm._v("http://www.adobe.com/es/privacy/cookies.html")]
                  ),
                ]),
                _c("p", [
                  _vm._v("Desde "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " aconsejamos seguir los procedimientos arriba señalados. No obstante, si Usted desease evitar la instalación de Google Analytics, le informamos de que Google ha creado una aplicación para tal fin: “El Complemento de inhabilitación para navegadores de Google Analytics”. Para utilizarla, haga clic"
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://tools.google.com/dlpage/gaoptout?hl=es",
                      },
                    },
                    [_vm._v("aquí.")]
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "Si como consecuencia de la eliminación o negativa a la instalación de cookies necesarias para la navegación o la obtención de los servicios solicitados se produjera cualquier incidencia, "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " no asumirá ningún tipo de responsabilidad por los posibles inconvenientes que tales situaciones pudieran conllevar."
                  ),
                ]),
                _c("h3", [_vm._v("Consentimiento.")]),
                _c("p", [
                  _vm._v(
                    "Le indicamos que si continúa navegando por la Web de "
                  ),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    ", disfrutando de los productos y servicios que ésta ofrece, entenderemos que consiente y acepta la instalación de las cookies arriba descritas."
                  ),
                ]),
                _c("p", [
                  _vm._v("Es importante que tenga en cuenta que "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(" ofrece enlace a websites de terceros. Desde "),
                  _c("strong", [_vm._v("SPAIN HERITAGE")]),
                  _vm._v(
                    " le recomendamos que, de igual modo que con este sitio Web, revise la política de cookies propia de cada website, puesto que ésta puede diferir de la presente."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "En todo caso, si desea hacernos llegar algún comentario o solicitar más información sobre el uso de cookies en nuestro sitio Web, puede hacerlo fácilmente por correo electrónico en la dirección "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "mailto:protecciondedatos@spainheritagenetwork.com",
                      },
                    },
                    [_vm._v("protecciondedatos@spainheritagenetwork.com.")]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }