<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">{{$t('default.FOOTER_PRIVACY_AND_COOKIES')}} </p>
            </div>
        </div>
        <div class="container-fluid px-xl-5 px-1 cokkies-main">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-xl-5">
                    <div class="d-flex flex-column pt-3 col-12 px-md-5">     
                        <h5 class="mb-0"><strong><small>{{tipoHeader}} {{monumentoNombre}}</small></strong></h5>
                        <p class="mb-0"><strong><small>{{tipoSubheader}} {{monumentoNombre}}</small></strong></p>

                        <div class="h-100 pt-5 ps-sm-3 pe-0 spainhn-text d-flex flex-column" style="border-radius:10px;">
                            <h1>Pol&iacute;tica de Privacidad</h1>

                            <p>La presente pol&iacute;tica de privacidad y de protecci&oacute;n de datos est&aacute; orientada a facilitarle cu&aacute;nta informaci&oacute;n necesita para comprender el alcance y el objeto del tratamiento de datos para que la aceptaci&oacute;n, absolutamente necesaria para poder acceder y utilizar la web, se asiente sobre un consentimiento perfectamente informado recogido en la normativa en materia de protecci&oacute;n de datos que resulta de aplicaci&oacute;n en cada momento, normativa constituida por el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos y por el que se deroga la Directiva 95/46/CE (&ldquo;RGPD&rdquo;), as&iacute; como la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales (&ldquo;LOPDGDD&rdquo;).</p>

                            <h3>&iquest;Qui&eacute;n es el responsable del tratamiento de sus datos?</h3>

                            <ul>
                                <li>SPAIN HERITAGE TOURS S.L. (en adelante,&nbsp;<strong>&ldquo;SPAIN HERITAGE &rdquo;</strong>)</li>
                                <li>CIF: B-88196423.</li>
                                <li>Datos de contacto:
                                <ul>
                                    <li>Domicilio social: Rodriguez Marín 61 &ndash; Bajo Izquierda, 28016 - Madrid.</li>
                                    <li>E-mail:&nbsp;<a href="mailto:protecciondedatos@spainheritagenetwork.com">protecciondedatos@spainheritagenetwork.com.</a></li>
                                </ul>
                                </li>
                            </ul>

                            <h3>&iquest;Con qu&eacute; finalidad tratamos sus datos personales?</h3>

                            <p>En&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;tratamos la informaci&oacute;n que nos facilitan las personas interesadas para gestionar la solicitud de informaci&oacute;n y, en su caso, contratar nuestros servicios. El uso de los datos es el siguiente:</p>

                            <ul>
                                <li>Gesti&oacute;n y control de la relaci&oacute;n contractual o, en su caso precontractual, adquirida con el usuario mediante su registro y/o contrataci&oacute;n de servicios.
                                <ul>
                                    <li>Gesti&oacute;n del servicio contratado. Compra de Entradas y Eventos: Gestionar la contrataci&oacute;n de los productos y servicios ofertados a trav&eacute;s del Sitio Web o por v&iacute;a telef&oacute;nica. Durante el proceso de contrataci&oacute;n se podr&aacute;n recabar datos financieros (tarjetas bancarias), pero no se lo solicitaremos nosotros, sino la pasarela de pago con la finalidad de comunicarlos a las entidades que gestionan el cobro.</li>
                                    <li>Acceso al &aacute;rea privada del Usuario. Gestionar el registro y los datos posteriores que los Usuarios pudieran generar a trav&eacute;s de dicha &aacute;rea con la finalidad de llevar a cabo la administraci&oacute;n general de su cuenta, mantenimiento y control de la misma.
                                    <ul>
                                        <li>Realizar una correcta gesti&oacute;n y control de la relaci&oacute;n contractual adquirida con el usuario mediante su registro y/o adquisici&oacute;n de productos o servicios.</li>
                                        <li>Mejorar los servicios a los que se suscribas mediante el estudio de su comportamiento como usuario para adaptarlo a sus necesidades y gustos.</li>
                                        <li>Aquellos otros fines necesarios para el desarrollo, cumplimiento y control de la relaci&oacute;n contractual que el Usuario mantenga con&nbsp;<strong>SPAIN HERITAGE.</strong></li>
                                        <li>Gestionar y tratar las posibles incidencias y el mantenimiento de los servicios que&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;ofrece o intermedia.</li>
                                        <li>Proporcionar servicios de ubicaci&oacute;n si elige compartir su ubicaci&oacute;n geogr&aacute;fica</li>
                                    </ul>
                                    </li>
                                    <li><strong>SPAIN HERITAGE</strong>&nbsp;podr&aacute; remitir por cualquier medio, comunicaciones comerciales referentes a productos o servicios de su propia empresa que sean similares a los que inicialmente fueron objeto de contrataci&oacute;n, sin perjuicio de que el usuario pueda oponerse en cualquier momento.</li>
                                </ul>
                                </li>
                                <li><strong>SPAIN HERITAGE</strong>&nbsp;te recuerda que no almacena datos de tu tarjeta de cr&eacute;dito sino simplemente aquella informaci&oacute;n necesaria para la correcta gesti&oacute;n de los servicios contratados por el cliente.</li>
                            </ul>

                            <h3>&iquest;Qu&eacute; uso le damos a la informaci&oacute;n que recopilamos?</h3>

                            <p>A continuaci&oacute;n informamos de las finalidades de tratamiento de sus datos personales:</p>

                            <p>Cuando accede a nuestros Servicios, podemos recopilar cierta informaci&oacute;n autom&aacute;ticamente desde su dispositivo, como su direcci&oacute;n IP, su direcci&oacute;n MAC o el identificador del dispositivo.</p>

                            <ul>
                                <li>Informaci&oacute;n que usted proporciona de forma voluntaria
                                <ul>
                                    <li>informaci&oacute;n de registro: Los datos personales de registro que proporciona cuando crea una cuenta en nuestro sistema de&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;, nos permite darle acceso a los Servicios y proporcion&aacute;rselos. Tambi&eacute;n usamos esta informaci&oacute;n para autenticar su acceso a los Servicios, contactar con usted para conocer su opini&oacute;n y notificarle sobre cambios o actualizaciones importantes en nuestros Servicios.</li>
                                    <li>Informaci&oacute;n de transacci&oacute;n: Nos permite procesar la compra de la entrada al espect&aacute;culo elegido y enviarle una factura precisa. Asimismo, nos ayuda a nosotros a ponernos en contacto con usted cuando sea necesario.
                                    <p>Podr&aacute;n ser solicitados datos adicionales de tipo econ&oacute;mico o financiero (como por el ejemplo el n&uacute;mero de su tarjeta de cr&eacute;dito o d&eacute;bito) al objeto de llevar a cabo la ejecuci&oacute;n de los servicios, pero dichos datos no ser&aacute;n almacenados en nuestro sistema de pago, sino que se recopilar&aacute;n a trav&eacute;s de la pasarela de pago.</p>
                                    </li>
                                </ul>
                                </li>
                                <li>Informaci&oacute;n que recopilamos autom&aacute;ticamente
                                <ul>
                                    <li>Informaci&oacute;n de actividad. Si elige compartir su ubicaci&oacute;n geogr&aacute;fica con nosotros, podemos utilizar esta informaci&oacute;n para mostrarle contenido que sea local para usted. Tambi&eacute;n podemos proporcionarle contenido y anuncios personalizados en funci&oacute;n de sus interacciones previas con nuestros Servicios y nuestro conocimiento de sus preferencias que deducimos a partir de su historial de uso y compra en relaci&oacute;n con los Servicios.</li>
                                </ul>
                                </li>
                                <li>Al igual que muchos sitios web y servicios en l&iacute;nea, utilizamos cookies y otras tecnolog&iacute;as para mantener un registro de su interacci&oacute;n con nuestros Servicios. Las cookies tambi&eacute;n nos ayudan a administrar una gama de funciones y contenido, as&iacute; como a almacenar b&uacute;squedas y volver a presentar su informaci&oacute;n en el momento de realizar el Pedido. Para obtener m&aacute;s informaci&oacute;n sobre los tipos de cookies y tecnolog&iacute;as similares que utilizamos y por qu&eacute; y c&oacute;mo puede controlar dichas tecnolog&iacute;as, consulte nuestra Pol&iacute;tica de Cookies.</li>
                            </ul>

                            <h3>&iquest;Por cu&aacute;nto tiempo conservaremos sus datos?</h3>

                            <p>Los datos proporcionados ser&aacute;n conservados mientras tenga una cuenta con nosotros o para que podamos cumplir con nuestras obligaciones legales, fiscales o contables, siendo cancelados al vencimiento del contrato que usted haya suscrito con SPAIN HERITAGE. Es decir, los datos ser&aacute;n bloqueados y conservados durante el plazo de prescripci&oacute;n de las acciones que pudieran derivarse de la relaci&oacute;n contractual por usted suscrita, y posteriormente ser&aacute;n suprimimos.</p>

                            <p>En caso de que se oponga a que tratemos ciertos datos personales (tambi&eacute;n en relaci&oacute;n con la recepci&oacute;n de nuestras comunicaciones de marketing), conservaremos un registro de su oposici&oacute;n al tratamiento de sus datos personales para que podamos seguir respetando sus deseos.</p>

                            <h3>&iquest;Cu&aacute;l es la legitimaci&oacute;n para el tratamiento de sus datos?</h3>

                            <p>La base legal para el tratamiento de sus datos es:</p>

                            <p>Te informamos asimismo de que la falta de aportaci&oacute;n de la informaci&oacute;n requerida supone la imposibilidad de suscripci&oacute;n y cumplimiento del contrato.</p>

                            <ul>
                                <li>Gesti&oacute;n de medidas precontractuales y, en su caso, contractuales basado en el art&iacute;culo 6.1.b) del RGPD, pues recabamos y tratamos sus datos personales principalmente para la ejecuci&oacute;n de las obligaciones contractuales se&ntilde;aladas anteriormente as&iacute; como en nuestras&nbsp;<a href="https://www.spainheritagenetwork.com/condiciones-generales">Condiciones Generales de Venta.</a>

                                <ul>
                                    <li>En los supuestos en los que el usuario se haya puesto en contacto con nosotros solicitando determinada informaci&oacute;n, podamos contactar nuevamente para confirmar si, respecto de esa solicitud, necesita algo m&aacute;s.</li>
                                    <li>La posible ejecuci&oacute;n de los servicios que haya suscrito con&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;seg&uacute;n los t&eacute;rminos y condiciones que figuran en cada servicio contratado.</li>
                                </ul>
                                </li>
                                <li>El consentimiento inequ&iacute;voco basado en el art&iacute;culo 6.1.a) del RGPD, en caso que no seas cliente, para el env&iacute;o de comunicaciones comerciales, novedades y newsletter.</li>
                                <li>Podremos obtener y tratar sus datos personales para nuestros intereses leg&iacute;timos basado en el art&iacute;culo 6.1.f) del RGPD (o los de cualquier tercero). Tales intereses leg&iacute;timos incluyen:
                                <ul>
                                    <li>Establecer relaciones comerciales con usted o hacerle ofertas que creemos que ser&aacute;n de su inter&eacute;s;</li>
                                    <li>Analizar y comprender c&oacute;mo se utilizan nuestros servicios y as&iacute; mejorarlos;</li>
                                    <li>Para la prevenci&oacute;n del fraude o de otros actos delictivos; o</li>
                                    <li>Para mantener seguros nuestros sistema</li>
                                    <li>La oferta de productos y servicios si ya eres cliente, se encuentra su legitimaci&oacute;n en el inter&eacute;s leg&iacute;timo del responsable del tratamiento, pudiendo manifestar el Cliente en cualquier momento su oposici&oacute;n a este tipo de tratamiento sin que en ning&uacute;n caso el ejercicio de este derecho condicione la ejecuci&oacute;n del contrato.</li>
                                    <li>Para el cumplimiento de obligaciones legales.</li>
                                </ul>
                                </li>
                            </ul>

                            <h3>&iquest;A qu&eacute; destinatarios se comunicar&aacute;n sus datos?</h3>

                            <p>Los datos tambi&eacute;n podr&aacute;n ser comunicados a terceros en los siguientes supuestos:</p>

                            <ul>
                                <li>&Uacute;nicamente en el supuesto que finalmente contrates con nosotros, tus datos se comunicar&aacute;n a los proveedores que sean necesarios para la tramitaci&oacute;n de del contrato que hayas suscrito con&nbsp;<strong>SPAIN HERITAGE</strong>. De este modo:

                                <ul>
                                    <li>Encargados de tratamiento de&nbsp;<strong>SPAIN HERITAGE</strong>. Podr&aacute;n acceder a los datos de car&aacute;cter personal de los Usuarios aquellos terceros o colaboradores que presten servicios a&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;(empresas de servicios inform&aacute;ticos o de IT, empresas de env&iacute;o de comunicaciones y agencias de marketing).</li>
                                    <li>Proveedores, intermediarios, Organismos e Instituciones P&uacute;blicas y propietarios privados gestores de los Castillos y Palacios dados de alta en nuestro sistema, a quienes les cedemos, necesariamente, los datos personales estrictamente necesarios para la ejecuci&oacute;n del servicio que hayas contratado. De esta manera, dicho Gestor o Propietario tratar&aacute; la informaci&oacute;n para la gesti&oacute;n del servicio contratado a trav&eacute;s de nuestra web.</li>
                                </ul>
                                </li>
                                <li>Una vez que hayas dado tu consentimiento para la distribuci&oacute;n y / o de la divulgaci&oacute;n de sus datos.</li>
                                <li>Si estamos en la obligaci&oacute;n de revelar o compartir sus datos personales con el fin de cumplir con cualquier obligaci&oacute;n legal, o para proteger los derechos, propiedad o seguridad de&nbsp;<strong>SPAIN HERITAGE</strong>, nuestros clientes u otros.</li>
                                <li>Por disposici&oacute;n de Ley, Reglamento o en virtud de una decisi&oacute;n de una autoridad competente, judicial o reguladora o, si es necesario, a fin de preservar sus derechos e intereses.</li>
                            </ul>

                            <h3>&iquest;Cu&aacute;les son sus derechos cuando nos facilita sus datos?</h3>

                            <p>Cualquier persona tiene derecho a obtener confirmaci&oacute;n sobre si en&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;estamos tratando datos personales que les conciernen.</p>

                            <p>Cualquier interesado podr&aacute; ejercitar los siguientes derechos mediante correo postal a la direcci&oacute;n Rodríguez Marín 61 &ndash; Bajo Izquierda, 28016 - Madrid, o mediante correo electr&oacute;nico a la direcci&oacute;n&nbsp;<a href="mailto:protecciondedatos@spainheritagenetwork.com">protecciondedatos@spainheritagenetwork.com.</a></p>

                            <p>Cualquier persona tiene derecho a obtener informaci&oacute;n sobre si en&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;estamos tratando datos personales que les conciernan, o no.</p>

                            <p>Las personas interesadas tienen derecho a solicitar la rectificaci&oacute;n de los datos inexactos o, en su caso, solicitar su supresi&oacute;n cuando, entre otros motivos, los datos ya nos sean necesarios para los que fines para los que fueron recogidos.</p>

                            <p>En determinadas circunstancias, los interesados podr&aacute;n solicitar la limitaci&oacute;n del tratamiento de sus datos, en cuyo caso &uacute;nicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</p>

                            <p>En cuanto a los tratamientos basados en la obtenci&oacute;n de su consentimiento, usted podr&aacute; revocarlo en cualquier momento, siempre y cuando el tratamiento no sea necesario para el cumplimiento del contrato, dirigi&eacute;ndose a la siguiente direcci&oacute;n de correo electr&oacute;nico:&nbsp;<a href="mailto:protecciondedatos@spainheritagenetwork.com">protecciondedatos@spainheritagenetwork.com.</a></p>

                            <p>La retirada del consentimiento no afectar&aacute; a la licitud del tratamiento basada en el consentimiento previo a su retirada.&nbsp;<strong>&ldquo;SPAIN HERITAGE &rdquo;</strong>, dejar&aacute; de tratar los datos, salvo por motivos leg&iacute;timos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>

                            <p>En el caso que resulte de aplicaci&oacute;n, usted podr&aacute; solicitad la portabilidad de sus datos.</p>

                            <p>Es el supuesto en el que los datos personales son utilizados para elaborar perfiles relacionados con las preferencias, fiabilidad, comportamiento, etc.</p>

                            <p>En todo caso, usted podr&aacute; presentar una reclamaci&oacute;n ante la Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos especialmente cuando no haya obtenido satisfacci&oacute;n en el ejercicio de sus derechos. La direcci&oacute;n de la Agencia es C/ Jorge Juan 6, 28001 y su p&aacute;gina web&nbsp;<a href="https://www.aepd.es/">www.agpd.es.</a></p>

                            <ol>
                                <li><strong>Derecho de acceso:</strong></li>
                                <li><strong>Derecho de rectificaci&oacute;n y supresi&oacute;n:</strong></li>
                                <li><strong>Derecho a la limitaci&oacute;n del tratamiento:</strong></li>
                                <li><strong>Derecho de oposici&oacute;n:</strong></li>
                                <li><strong>Derecho de portabilidad:</strong></li>
                                <li><strong>Derecho a no ser objeto de decisiones automatizadas:</strong></li>
                            </ol>

                            <h1>Pol&iacute;tica de Cookies</h1>

                            <p>En cumplimiento con lo establecido en el art&iacute;culo 22.2 de la Ley de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSI) y en adecuaci&oacute;n con la Directiva Europea 2009/136/CE, SPAIN HERITAGE TOURS S.L. (en adelante,&nbsp;<strong>SPAIN HERITAGE</strong>) le informa que este sitio Web utiliza cookies.</p>

                            <h3>&iquest;Qu&eacute; son las cookies?</h3>

                            <p>Una cookie es un peque&ntilde;o fichero de texto que almacena informaci&oacute;n sobre su experiencia de usuario en su propio terminal (ordenador, Tablet, Smartphone, etc.). Cada vez que visite este sitio Web, nuestro servidor reconocer&aacute; la cookie utiliz&aacute;ndola para mejorar su experiencia de navegaci&oacute;n, de modo que pueda obtener el mejor resultado de su visita a nuestra Web.</p>

                            <h3>&iquest;Qu&eacute; tipo de cookies existen y cu&aacute;les son las utilizadas por&nbsp;<strong>SPAIN HERITAGE</strong></h3>

                            <p>En concreto,&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;utiliza las siguientes cookies:</p>

                            <ul>
                                <li><strong>Cookies t&eacute;cnicas o funcionales.</strong>&nbsp;Las cookies funcionales son las estrictamente necesarias e indispensables para proporcionar los servicios solicitados por los usuarios en este sitio Web de manera &oacute;ptima. Su funci&oacute;n es recoger datos puramente t&eacute;cnicos (sistema operativo, control del tr&aacute;fico, recordar pedidos, seguridad, etc.), sin recabar datos identificativos de su persona.</li>
                                <li><strong>Cookies de personalizaci&oacute;n.</strong>&nbsp;Estas cookies est&aacute;n destinadas a fijar los criterios b&aacute;sicos y concretos del terminal del usuario (idioma, tipo de navegador, configuraci&oacute;n regional de acceso al servicio, etc.), sin que sirvan para configurar un perfil de usuario.</li>
                                <li><strong>Cookies anal&iacute;ticas.</strong>&nbsp;Las cookies anal&iacute;ticas recogen informaci&oacute;n para evaluar el uso que se hace de la Web y la actividad general de la misma. La anal&iacute;tica de la Web es la medida, recolecci&oacute;n, an&aacute;lisis y creaci&oacute;n de informes de los datos de Internet con el prop&oacute;sito de entender y optimizar el uso del sitio Web.</li>
                            </ul>

                            <p>&Uacute;nicamente para su informaci&oacute;n, le se&ntilde;alamos qu&eacute; otros tipos de cookies existen. No obstante, le indicamos que SPAIN HERITAGE no las utiliza:</p>

                            <ul>
                                <li><strong>Cookies sociales.</strong>&nbsp;Las cookies sociales son las utilizadas por las redes sociales externas (Facebook, Google+ y Twitter). Su funci&oacute;n es controlar la interacci&oacute;n con los widgets sociales dentro de la p&aacute;gina.</li>
                                <li><strong>Cookies de publicidad comportamental.</strong>&nbsp;Son aquellas con la capacidad de almacenar datos referentes a su comportamiento y h&aacute;bitos de navegaci&oacute;n, como, por ejemplo, sus destinos y actividades preferidos. Fruto de este almacenamiento y procesamiento, es posible obtener un perfil espec&iacute;fico del usuario que mejore y d&eacute; mayor eficacia a las ofertas publicitarias que se muestran durante la navegaci&oacute;n.</li>
                            </ul>

                            <p>En concreto, las cookies que utiliza&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;son:</p>

                            <p><strong>COOKIES PROPIAS DE SPAIN HERITAGE</strong></p>

                            <table>
                                <tbody>
                                    <tr>
                                        <th><strong>Nombre</strong></th>
                                        <th><strong>Tiempo de caducidad</strong></th>
                                        <th><strong>Descripci&oacute;n</strong></th>
                                    </tr>
                                    <tr>
                                        <td><strong>__utma</strong></td>
                                        <td>2 a&ntilde;os desde la configuraci&oacute;n/actualizaci&oacute;n</td>
                                        <td>Se utiliza para distinguir usuarios y sesiones. Se crea cuando la biblioteca javascript se ejecuta y no existen cookies _utma previas. Se actualiza cada vez que los datos se env&iacute;an a Google Analytics.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>__utmb</strong></td>
                                        <td>30 min. desde la configuraci&oacute;n/actualizaci&oacute;n</td>
                                        <td>Usada para determinar nuevas sesiones/visitas. Se crea cuando la biblioteca javascript se ejecuta y no existen cookies __utbm previas. Se actualiza cada vez que los datos se env&iacute;an a Google Analytics.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>__utmc</strong></td>
                                        <td>Final de la sesi&oacute;n de navegador</td>
                                        <td>No se utiliza en ga.js. Utilizado para la interoperabilidad con urchin.js. Hist&oacute;ricamente, esta cookie operaba en conjunto con la cookie __utmb para determinar si el usuario estaba en una nueva sesi&oacute;n/visita.</td>
                                    </tr>
                                    <tr>
                                        <td><strong>__utmz</strong></td>
                                        <td>6 meses desde la configuraci&oacute;n/actualizaci&oacute;n</td>
                                        <td>Almacena la fuente de tr&aacute;fico o campa&ntilde;a que explica c&oacute;mo lleg&oacute; el usuario a la web. Se crea cuando la biblioteca javascript se ejecuta y se actualiza cada vez que los datos se env&iacute;an a Google Analytics.</td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>&iquest;Por qu&eacute; utilizamos Google Analytics?</h3>

                            <p>Google Analytics es la herramienta de an&aacute;lisis web gratuita de Google que ayuda a los propietarios de los sitios web a comprender, mediante datos agregados, c&oacute;mo interact&uacute;an los visitantes &ndash;sin identificarles- con su sitio web, para as&iacute; comprender tendencias y modos de uso de la p&aacute;gina, obteniendo informes que ayudan a mejorar continuamente las p&aacute;ginas.</p>

                            <p>A trav&eacute;s de Google Analytics,&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;no recibe informaci&oacute;n sobre la navegaci&oacute;n del usuario ajena a la realizada en esta Web.</p>

                            <p>Google, Inc. es una compa&ntilde;&iacute;a de Delaware, cuya oficina principal est&aacute; en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (&ldquo;Google&rdquo;). La informaci&oacute;n que genera la cookie acerca de su uso del website (incluyendo su direcci&oacute;n IP) ser&aacute; directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usar&aacute; esta informaci&oacute;n por cuenta nuestra con el prop&oacute;sito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados con la actividad del website y el uso de Internet. Google podr&aacute; transmitir dicha informaci&oacute;n a terceros cuando as&iacute; se lo requiera la legislaci&oacute;n, o cuando dichos terceros procesen la informaci&oacute;n por cuenta de Google. Google no asociar&aacute; su direcci&oacute;n IP con ning&uacute;n otro dato del que disponga Google.</p>

                            <p>Este aviso viene a cumplir con el requerimiento de la pol&iacute;tica de privacidad de Google Analytics que exige a todos los propietarios de webs que actualicen su pol&iacute;tica de privacidad e informen a los usuarios seg&uacute;n las exigencias de calidad de Google Analytics. Para m&aacute;s informaci&oacute;n sobre la pol&iacute;tica de privacidad de Google puede visitar la siguiente direcci&oacute;n:&nbsp;<a href="http://www.google.com/policies/technologies/ads/">www.google.com/policies/technologies/ads/</a></p>

                            <p>Por todo lo anterior,&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;informa a sus usuarios de que (i) es Google, a trav&eacute;s de su herramienta Google Analytics, quien recaba la informaci&oacute;n sobre el tr&aacute;fico en esta Web en su propio nombre (en ning&uacute;n caso en nombre de&nbsp;<strong>SPAIN HERITAGE</strong>), teniendo &uacute;nicamente SPAIN HERITAGE acceso a los datos estad&iacute;sticos agregados de dicho tr&aacute;fico ajenos a cualquier identificaci&oacute;n personal; (ii) Google (y no&nbsp;<strong>SPAIN HERITAGE</strong>) es quien decide la finalidad del tratamiento y uso de los datos obtenidos mediante Google Analytics.</p>

                            <h3>&iquest;C&oacute;mo puedo evitar el almacenamiento de cookies en mi ordenador?</h3>

                            <p>La mayor&iacute;a de los navegadores aceptan la utilizaci&oacute;n de cookies de forma autom&aacute;tica, pero le informamos de que Usted puede modificar la configuraci&oacute;n de su navegador para evitar su utilizaci&oacute;n e, incluso, eliminar las cookies que pudieran estar instaladas previamente en su terminal.</p>

                            <p>A continuaci&oacute;n le indicamos, para los navegadores m&aacute;s importantes, d&oacute;nde puede obtener la informaci&oacute;n necesaria para tal fin:</p>

                            <ul>
                                <li>Internet Explorer&reg;:&nbsp;<a href="http://windows.microsoft.com/es-eS/windows7/Block-enable-or-allow-cookies/">http://windows.microsoft.com/es-eS/windows7/Block-enable-or-allow-cookies.</a></li>
                                <li>Safari&reg;:&nbsp;<a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></li>
                                <li><a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">Chrome&reg;:&nbsp;</a><a href="https://www.google.es/intl/es/policies/technologies/cookies/">https://www.google.es/intl/es/policies/technologies/cookies/</a></li>
                                <li>Firefox&reg; :&nbsp;<a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">href=&quot;http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
                                <li>Opera&reg;:&nbsp;<a href="http://help.opera.com/Linux/10.60/es-ES/cookies.html">http://help.opera.com/Linux/10.60/es-ES/cookies.html</a></li>
                            </ul>

                            <p>Asimismo, dada la extensi&oacute;n de su uso, le recomendamos que consulte la pol&iacute;tica de cookies ofrece Adobe&reg;:&nbsp;<a href="http://www.adobe.com/es/privacy/cookies.html">http://www.adobe.com/es/privacy/cookies.html</a></p>

                            <p>Desde&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;aconsejamos seguir los procedimientos arriba se&ntilde;alados. No obstante, si Usted desease evitar la instalaci&oacute;n de Google Analytics, le informamos de que Google ha creado una aplicaci&oacute;n para tal fin: &ldquo;El Complemento de inhabilitaci&oacute;n para navegadores de Google Analytics&rdquo;. Para utilizarla, haga clic<a href="https://tools.google.com/dlpage/gaoptout?hl=es">aqu&iacute;.</a></p>

                            <p>Si como consecuencia de la eliminaci&oacute;n o negativa a la instalaci&oacute;n de cookies necesarias para la navegaci&oacute;n o la obtenci&oacute;n de los servicios solicitados se produjera cualquier incidencia,&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;no asumir&aacute; ning&uacute;n tipo de responsabilidad por los posibles inconvenientes que tales situaciones pudieran conllevar.</p>

                            <h3>Consentimiento.</h3>

                            <p>Le indicamos que si contin&uacute;a navegando por la Web de&nbsp;<strong>SPAIN HERITAGE</strong>, disfrutando de los productos y servicios que &eacute;sta ofrece, entenderemos que consiente y acepta la instalaci&oacute;n de las cookies arriba descritas.</p>

                            <p>Es importante que tenga en cuenta que&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;ofrece enlace a websites de terceros. Desde&nbsp;<strong>SPAIN HERITAGE</strong>&nbsp;le recomendamos que, de igual modo que con este sitio Web, revise la pol&iacute;tica de cookies propia de cada website, puesto que &eacute;sta puede diferir de la presente.</p>

                            <p>En todo caso, si desea hacernos llegar alg&uacute;n comentario o solicitar m&aacute;s informaci&oacute;n sobre el uso de cookies en nuestro sitio Web, puede hacerlo f&aacute;cilmente por correo electr&oacute;nico en la direcci&oacute;n&nbsp;<a href="mailto:protecciondedatos@spainheritagenetwork.com">protecciondedatos@spainheritagenetwork.com.</a></p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>

import DefaultLayout from '../layouts/DefaultLayout.vue'

export default {
    components:{
        DefaultLayout, 
        // IconPalace, 
        // IconRealPalace, 
        // IconArt, 
        // IconWalledEnclosure,
        // IconMonastery, 
        // Cards4,
        // CardsThumbnails// Cards
    },
    data(){
        return{
       
        }
    },
    methods:{
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },
    setup() {
        
    },
    created(){
       
    },
    computed: {
    },
    async mounted(){
        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', 'https://consent.cookiebot.com/2d16d473-0e9b-4249-9d18-ae4f03cd7ca8/cd.js');
        document.head.appendChild(externalScript);
    }
}
</script>


<style scoped>
    .text-columns{
        column-count: 3;
        /* height: 50vh; */
    }
    .disable-selector{
        color:rgba(225,225,225);
    }
    .slide-show-container{
        position: relative;
        width: 100%;
        margin: auto;
    }
    .spainhn-slides{
        display: none;
    }

    /* Next & previous buttons */
    .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    }

    /* Position the "next button" to the right */
    .next {
    right: 0;
    border-radius: 3px 0 0 3px;
    }
    
    /* Fading animation */
    .fade {
        animation-name: fade;
        animation-duration: 10s;
    }
    .cokkies-main li,.cokkies-main p{
        width: 100%;
        overflow-wrap: break-word;
    }
    .cokkies-main table td{
        word-break: break-word;
    }

    @keyframes fade {
        from {opacity: .4}
        to {opacity: 1}
    }

        @media (min-width: 768px) {
        .text-columns{
            column-count: 3;
            /* height: 50vh; */
        }
    }

    @media (max-width: 768px){.text-columns{
        column-count: 2;
        /* height: 50vh; */
    }}

    @media (max-width: 424px){
        .text-columns{
        column-count: 1;
        /* height: 50vh; */
    }}
</style>